import axios from 'axios';
import parse from 'html-react-parser';
import { useQuery } from 'react-query';
import { WP_REST_API_Attachment } from 'wp-types';

import { MediaItem, PortfolioPostPropsWithMedia } from '../../../types';
import { apiUrl } from '../../../utils';
import { ContentLoader } from '../../content-loader/content-loader';
import { ThreeDimensionalPost } from '../../portfolio/post/3d-post';
import { ProjectMeta } from './projectMeta';
import {
  Bubble4,
  GalleryGridColumn,
  GalleryGridContent,
  GalleryGridItem,
  IframeMedia,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ImageOverlay,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ImageWrapper,
  PortfolioPostContent,
  ProjectViewBackButton,
  ProjectWrapper,
} from './styles';

interface ProjectProps {
  id: number;
  onBackClick: () => void;
}

const createColumns = async (columns: number, items: MediaItem[]) => {
  const columnsArray: MediaItem[][] = Array.from({ length: columns }, () => []);
  const columnHeights: number[] = Array(columns).fill(0);

  const getImageHeight = async (imageUrl: string): Promise<number> => {
    return await new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(img.height);
      };
      img.onerror = () => {
        reject(new Error('Failed to load image'));
      };
      img.src = imageUrl;
    });
  };

  const imageHeightsPromises = items.map(async (item) => {
    const height = item.type === 'image' ? await getImageHeight(item.content) : 400; // presume 400px height for iframe/model
    return height;
  });
  const imageHeights = await Promise.all(imageHeightsPromises);

  items.forEach((item, index) => {
    const columnIndex = columnHeights.indexOf(Math.min(...columnHeights));
    columnsArray[columnIndex].push(item);
    columnHeights[columnIndex] += imageHeights[index];
  });

  return columnsArray;
};

const postComponentByType = (type: string, content: string, key: number) => {
  switch (type) {
    case 'image':
      return (
        <img key={key} src={content} alt={'?'} style={{ width: '100%' }} />
        // <ImageWrapper key={key}>
        //   <ImageOverlay>Zoom in</ImageOverlay>
        //   <img src={content} alt={'?'} style={{ width: '100%' }} />
        // </ImageWrapper>
      );
    case 'iframe':
      return <IframeMedia key={key}>{parse(content)}</IframeMedia>;
    case 'model':
      return (
        <div style={{ background: '#333' }} key={key}>
          <ThreeDimensionalPost fileUrl={content} height={400} width={400} />
        </div>
      );
    default:
      return null;
  }
};

export const TimelineProject = ({ id, onBackClick }: ProjectProps) => {
  const portfolioQuery = `${apiUrl}posts/${id}?_fields=id,title,date,acf`;
  const postMediaQuery = `${apiUrl}media?parent=${id}&_fields=data,source_url,media_details`;

  const { isLoading, error, data } = useQuery<PortfolioPostPropsWithMedia, Error>(['projectMedia', id], () =>
    axios.get(portfolioQuery).then((response) => {
      const { id, title, date, acf } = response.data;
      const { externalMedia, model, columns, content } = acf;

      return axios.get(postMediaQuery).then((attachmentResponse) => {
        // create mediaContent object with image/file attachments
        const mediaContent: MediaItem[] = attachmentResponse.data.map((mediaItem: WP_REST_API_Attachment) => {
          // const mediaDetails = mediaItem.media_details as unknown as {
          //   sizes: {
          //     medium: {
          //       source_url: string;
          //     };
          //     large: {
          //       source_url: string;
          //     };
          //   };
          // };
          // const mediumImage = mediaDetails.sizes.medium.source_url;

          return {
            type: 'image',
            // content: mediumImage,
            content: mediaItem.source_url,
          };
        });

        if (externalMedia) {
          mediaContent.push({
            type: 'iframe',
            content: externalMedia,
          });
        }

        if (model) {
          mediaContent.push({
            type: 'model',
            content: model.url,
          });
        }

        return createColumns(parseInt(columns), mediaContent).then(
          (mediaColumns) =>
            ({
              id: id,
              title: title.rendered,
              description: content,
              date: date,
              media: mediaColumns,
            }) as PortfolioPostPropsWithMedia,
        );
      });
    }),
  );

  return (
    <ProjectWrapper>
      <ProjectViewBackButton onClick={onBackClick}>Go back</ProjectViewBackButton>
      <ContentLoader
        isLoading={isLoading}
        error={error?.message}
        hasContent={!!data}
        errorPrefix="There was an error loading project content:"
      >
        {data && (
          <PortfolioPostContent>
            <ProjectMeta title={data.title} content={data.description} />
            <GalleryGridContent>
              <GalleryGridColumn>
                <ProjectMeta title={data.title} content={data.description} responsive />
                {data.media.map((item, index) => (
                  <GalleryGridItem key={index}>
                    {item.map(({ type, content }, itemIndex) => postComponentByType(type, content, itemIndex))}
                  </GalleryGridItem>
                ))}
              </GalleryGridColumn>
            </GalleryGridContent>
          </PortfolioPostContent>
        )}
      </ContentLoader>
      <Bubble4 />
    </ProjectWrapper>
  );
};
