import { createRef, useEffect } from 'react';
import styled from 'styled-components';
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';

interface ThreeDimensionalPostProps {
  fileUrl: string;
  width: number;
  height: number;
}

const ThreeContainer = styled.div`
  display: flex;
  > * {
    margin: 0 auto;
  }
`;

export const ThreeDimensionalPost = ({ fileUrl, width, height }: ThreeDimensionalPostProps) => {
  const postRef = createRef<HTMLDivElement>();
  const scene = new THREE.Scene();
  const loader = new FBXLoader();

  const renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setSize(width, height);
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;

  const camera = new THREE.PerspectiveCamera(30, width / height, 0.1, 10000);
  camera.position.set(1.5, 40, 120);
  camera.up.set(0, 1, 0);
  camera.lookAt(new THREE.Vector3(0, 0, 0));

  const light = new THREE.AmbientLight(0xffffff, 1);
  scene.add(light);

  const light2 = new THREE.PointLight(0xffffff, 3500);
  light2.position.set(-15, 50, -10);
  light2.castShadow = true;
  light2.shadow.radius = 10;
  scene.add(light2);

  const light3 = new THREE.PointLight(0xffffff, 1500);
  light2.position.set(0, 40, 60);
  light2.castShadow = true;
  light3.shadow.radius = 10;
  scene.add(light3);

  const geo = new THREE.PlaneGeometry(2000, 2000, 1, 1);
  const mat = new THREE.MeshPhongMaterial({ color: 0xeeeeee, side: THREE.DoubleSide });
  const plane = new THREE.Mesh(geo, mat);
  plane.rotateX(-Math.PI / 2);
  plane.receiveShadow = true;
  plane.castShadow = false;
  scene.add(plane);

  loader.load(
    fileUrl,
    (model) => {
      model.position.set(0, 4.2, 30);
      model.children.forEach((child: THREE.Object3D) => {
        child.castShadow = true;
        child.receiveShadow = true;
      });

      scene.add(model);
    },
    undefined,
    (error) => {
      console.error('error loading 3d model', error);
    },
  );

  useEffect(() => {
    if (postRef.current) {
      postRef.current.appendChild(renderer.domElement);
    }

    animate();
  }, []);

  const animate = () => {
    requestAnimationFrame(animate);

    scene.children.forEach((mesh: THREE.Object3D) => {
      if (mesh.type === 'Group') {
        mesh.rotation.z += 0.0025;
      }
    });

    renderer.render(scene, camera);
  };

  return <ThreeContainer ref={postRef} />;
};
