import { colord } from 'colord';

const palette = {
  background: '#F5F5F5',
  black: '#333',
  darkGray: '#555',
  lightGray: '#b9b9b9',
  border: '#ddd',

  violet: '#8e53ff',
  blue: '#79b7f1',
  electric: '#8AC3C4',
  green: '#AACA87',
  glaucous: '#6279B8',
  citron: '#E0C879',
  orange: '#FF975D',

  highlight: '#EDFF91',
};

const font = {
  primary: 'Roboto',
  secondary: 'Roboto Condensed',
};

const size = {
  xs: '0.5rem', // 8px
  sm: '1rem', // 16px
  md: '1.5rem', // 24px
  lg: '2rem', // 32px
  xl: '3rem', // 48px
};

export const theme = {
  responsive: {
    xs: '320px',
    sm: '640px',
    md: '860px',
    lg: '1280px',
    xl: '1800px',
  },
  font: {
    body: {
      fontSize: '1rem', // 16px
      family: 'Roboto',
    },
    p: {
      fontSize: '1rem', // 16px
      family: 'Roboto',
    },
    label: {
      fontSize: '1rem', // 16px
      family: 'Roboto',
    },
    h1: {
      fontSize: '3.25rem', // 52px
      family: font.secondary,
    },
    h2: {
      fontSize: size.xl, // 48px
      family: font.secondary,
    },
    h3: {
      fontSize: '2.5rem', // 40px
      family: font.secondary,
    },
    h4: {
      fontSize: size.md, // 24px
      family: font.secondary,
    },
    h5: {
      fontSize: '1.125rem', // 18px
      family: font.secondary,
    },
    h6: {
      fontSize: size.sm, // 16px
      family: font.secondary,
    },
  },
  colors: {
    selection: {
      background: palette.black,
      color: palette.highlight,
    },
    // section colors
    about: {
      link: palette.violet,
      border: palette.border,
    },
    experience: {
      timelineBorder: palette.border,
      work: palette.darkGray,
      study: palette.violet,
    },
    skills: {
      expertise: {
        default: palette.blue,
        hover: colord(palette.blue).lighten(0.1).toRgbString(),
      },
      coding: {
        default: palette.electric,
        hover: colord(palette.electric).lighten(0.1).toRgbString(),
      },
      design: {
        default: palette.green,
        hover: colord(palette.green).lighten(0.1).toRgbString(),
      },
    },
    portfolio: {
      item: {
        date: palette.darkGray,
      },
      filter: {
        active: palette.violet,
        inactive: palette.black,
      },
      nav: {
        active: palette.black,
        disabled: palette.lightGray,
      },
    },
  },
};

export const responsive = {
  xs: `@media (max-width: ${theme.responsive.xs})`,
  sm: `@media (max-width: ${theme.responsive.sm})`,
  md: `@media (max-width: ${theme.responsive.md})`,
  lg: `@media (max-width: ${theme.responsive.lg})`,
  xl: `@media (max-width: ${theme.responsive.xl})`,
};
