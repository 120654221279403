import styled from 'styled-components';

import { responsive } from '../../styles/theme';

export const TimelineHeader = styled.h4`
  padding: 0;
  position: absolute;
  transform: translateY(calc(-100% + 1px));
  border-bottom: 2px solid #333;
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 2rem;
  text-transform: uppercase;
  position: fixed;
  background: #eee;
  cursor: default;

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 2rem;
  }
`;

export const TimelineYearWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 0;
  padding-top: 32px;
`;

export const TimelineYear = styled.div`
  display: flex;
  padding-left: 3rem;

  ${responsive.md} {
    padding-left: 2rem;
  }
`;

export const TimelineYearText = styled.div`
  color: #8e8e8e;
  font-size: 14px;
  margin-top: -7px;
  margin-right: 1rem;
`;

export const TimelineYearBorder = styled.div`
  height: 1px;
  background: #ccc;
  flex-grow: 1;
`;

export const TimelineYearVerticalBorder = styled.div`
  width: 1px;
  background: #ccc;
`;

export const TimelineYearPreBorder = styled.div`
  width: 2rem;
  height: 1px;
  background: #ccc;

  ${responsive.sm} {
    width: 0;
  }
`;

export const CompanyImage = styled.div`
  svg,
  object {
    width: 24px;
    height: 24px;
    margin-bottom: 1rem;
  }
`;

export const TimelineProjectsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 1rem;
  font-size: 14px;
`;

export const TimelineProjectWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;

  &:hover {
    background: #edff91;
  }
`;

export const TimelineExperienceWrapper = styled.div<{ $visible?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-left: 4rem;
  position: relative;

  &:hover {
    ${TimelineHeader} {
      background: #ddd;
    }
  }

  ${responsive.md} {
    margin-left: 2rem;
    flex-grow: 1;
    display: ${(props) => (props.$visible ? 'flex' : 'none')};
  }

  ${responsive.sm} {
    margin-left: 0;
  }
`;

export const TimelineWrapper = styled.div`
  display: flex;
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  width: 100%;
  flex-basis: 0;
  flex-grow: 1;
  overflow-y: auto;

  ${responsive.md} {
    ${TimelineHeader} {
      display: none;
    }
  }
`;

export const TimelineExperiencesWrapper = styled.div`
  display: flex;
  margin-left: 100px;
  padding-top: 32px;

  ${responsive.md} {
    flex-grow: 1;
  }
`;

export const Tool = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: default;

  img,
  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
    margin-bottom: 0;
    flex-shrink: 0;

    path,
    rect,
    polygon,
    circle {
      transition: all 0.15s ease-in-out;
    }
  }

  /* &:not(:hover) { */
  svg {
    path,
    rect,
    polygon,
    circle {
      fill: #333;
    }

    path[fill='white'],
    path[fill='#FFFFFF'],
    path[fill='#000000'],
    polygon[fill='white'],
    polygon[fill='#FFFFFF'],
    rect[fill='white'],
    rect[fill='#FFFFFF'] {
      fill: white;
    }

    path[fill='#FF2BC2'] {
      fill: white;
    }

    path[stroke='#61dafb'] {
      fill: none;
      stroke: #333;
    }
  }
  /* } */

  /* &:hover {
    color: ${(props) => props.theme.colors.about.link};

    svg {
      path,
      rect,
      polygon,
      circle {
        fill: ${(props) => props.theme.colors.about.link};
      }

      path[fill='white'],
      path[fill='#FFFFFF'],
      path[fill='#000000'],
      polygon[fill='white'],
      polygon[fill='#FFFFFF'],
      rect[fill='white'],
      rect[fill='#FFFFFF'] {
        fill: white;
      }

      path[fill='#FF2BC2'] {
        fill: white;
      }

      path[stroke='#61dafb'] {
        fill: none;
        stroke: ${(props) => props.theme.colors.about.link};
      }
    }
  } */

  /* &:not(:hover) {
    background: #edff91;
  } */
`;

export const ToolText = styled.div`
  display: flex;
`;

export const ToolGroupVertical = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 2rem 0;
  justify-content: center;

  ${Tool}:not(:last-child) {
    margin-bottom: 1rem;
  }

  &:hover ${ToolText} {
    background: #edff91;
  }
`;

export const TimelineExperience = styled.div`
  border-left: 1px solid #333;
  border-top: 1px solid #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2rem 0 3rem;
  margin-right: auto;
  flex-shrink: 0;

  h5 {
    margin: 0 0 0.5rem 0;
    cursor: default;
  }

  label,
  p {
    margin: 0.25rem 0 0 0;
    font-size: 14px;
    max-width: 200px;
  }

  &:hover {
    > h5 {
      background: #edff91;
    }
    ${CompanyImage} {
      background: #edff91;
    }

    ${ToolText} {
      background: #edff91;
    }
  }

  ${responsive.md} {
    margin-right: 4rem;
  }
  ${responsive.sm} {
    margin-right: 0rem;
  }
`;
