import styled, { keyframes } from 'styled-components';

export const bubbles = keyframes`
    0%,
    80%,
    100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
`;

export const ContentLoaderWrapper = styled.div<{ $height?: number }>`
  background-color: rgba(0, 0, 0, 0.05);
  width: 100%;
  min-height: ${(props) => props.$height}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Dot = styled.div<{ $delay?: number }>`
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation-fill-mode: both;
  animation: ${bubbles} 1.8s infinite ease-in-out;
  animation-delay: ${(props) => props.$delay || 0}ms;
`;

export const Loader = styled.div`
  display: flex;

  ${Dot}:not(:last-child) {
    margin-right: 1.5rem;
  }
`;
