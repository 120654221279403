import { Head } from '../head/head';
// import { AboutMe } from './about-me/about-me';
import { Links } from '../links/links';
// import { Portfolio } from './portfolio';
import { Timeline } from '../timeline/timeline';
import { MainBlock } from './styles';

export const Main = () => {
  return (
    <MainBlock>
      <Head />
      <Links />
      <Timeline />
      {/* <AboutMe /> */}
      {/* <Portfolio /> */}
    </MainBlock>
  );
};
