import { MobileHeader, MobileHeaderH4 } from './styles';

interface ResponsiveHeaderProps {
  groups: string[];
  selectedGroup: string;
  onGroupClick: (groupName: string) => void;
}

export const ResponsiveHeader = ({ groups, selectedGroup, onGroupClick }: ResponsiveHeaderProps) => {
  return (
    <MobileHeader>
      {groups.map((group) => (
        <MobileHeaderH4 key={group} $selected={group === selectedGroup} onClick={() => onGroupClick(group)}>
          {group}
        </MobileHeaderH4>
      ))}
    </MobileHeader>
  );
};
