import styled from 'styled-components';

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin: -2vw auto 0 auto;
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  position: relative;
`;

export const LargeText = styled.h1`
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 500;
  margin: 0;
  color: #dddddd;
  font-size: 17.2vw;
  height: 14.5vw;
  z-index: 1;
  margin-top: -0.5rem;
`;

export const LargeTextWithBackground = styled(LargeText)`
  background: #ddd;
  color: #eee;
`;

export const SelfImage = styled.div`
  position: relative;
  width: 13vw;
  height: 13vw;
  display: flex;
  justify-content: center;
  background: #edff91;
  overflow: hidden;
  border-radius: 50%;
  flex-shrink: 0;

  svg {
    margin-top: min(1.5vw, 20px);
    width: 13vw;
    height: 13vw;
  }
`;

export const SelfBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`;

export const Bubble = styled.div`
  width: 20vw;
  height: 20vw;
  background: #edff91;
  border-radius: 50%;
  flex-shrink: 0;
  position: absolute;
  left: -6%;
  top: -51%;
`;

export const Bubble2 = styled.div`
  width: 4vw;
  height: 4vw;
  background: #edff91;
  border-radius: 50%;
  flex-shrink: 0;
  position: absolute;
  left: 25%;
  top: 66%;
  z-index: 2;
`;

export const Bubble3 = styled.div`
  width: 2vw;
  height: 2vw;
  background: #edff91;
  border-radius: 50%;
  flex-shrink: 0;
  position: absolute;
  left: 28%;
  top: 35%;
  z-index: 2;
`;
