import styled from 'styled-components';

import { responsive } from '../../styles/theme';

export const Contacts = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: center;
  background: #ddd;
  z-index: 2;
  margin-bottom: 2rem;
`;

export const Contact = styled.div`
  display: flex;
  height: 25px;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
`;

export const ContactLink = styled.a`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  height: 100%;
  flex-grow: 1;

  &:visited,
  &:active {
    color: inherit;
  }

  svg {
    height: 20px;
    width: 20px;
    margin: auto 15px auto 0;

    path {
      fill: #333;
    }
  }

  &:hover {
    background: #edff91;
  }

  ${responsive.sm} {
    svg {
      display: none;
    }
  }
`;
