import { config } from './wordpress-config';

const { protocol, hostname } = window.location;

const hostnameStrings = ['localhost', '[::]', '[::1]'];
const isLocalhost = Boolean(
  hostnameStrings.includes(hostname) || hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

export const apiUrl = `${protocol}//${hostname}${isLocalhost ? config.localApi : config.remoteApi}`;
