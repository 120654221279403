export const config = {
  localApi: ':10004/wp-json/wp/v2/',
  remoteApi: '/wordpress/wp-json/wp/v2/',
  pages: {
    aboutMe: 2,
  },
  categories: {
    portfolioNew: 15,
    experiencesNew: 24,
    stackNew: 25,

    portfolio: 3,
    everything: -1,
    development: [5, 13],
    design: 7,
    music: 9,
    experiences: 2,
    study: 4,
    work: 6,
  },
};
