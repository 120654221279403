import {
  TimelineYear,
  TimelineYearBorder,
  TimelineYearPreBorder,
  TimelineYearText,
  TimelineYearVerticalBorder,
  TimelineYearWrapper,
} from './styles';

interface YearWrapperProps {
  years: number[];
  yearHeight: number;
}

export const YearWrapper = ({ years, yearHeight }: YearWrapperProps) => {
  return (
    <TimelineYearWrapper>
      {years.map((year) => (
        <TimelineYear key={year} style={{ height: yearHeight }}>
          <TimelineYearText>{year}</TimelineYearText>
          <TimelineYearPreBorder />
          <TimelineYearVerticalBorder />
          <TimelineYearBorder />
        </TimelineYear>
      ))}
    </TimelineYearWrapper>
  );
};
