import parse from 'html-react-parser';
import { useMemo, useState } from 'react';
import { WP_Post, WP_REST_API_Post } from 'wp-types';

import { apiUrl } from '../../utils';
import { config } from '../../wordpress-config';
import { TimelineProject } from './project/project';
import { ResponsiveHeader } from './responsiveHeader/responsiveHeader';
import {
  CompanyImage,
  TimelineExperiencesWrapper,
  TimelineProjectsWrapper,
  TimelineProjectWrapper,
  TimelineWrapper,
  Tool,
  ToolGroupVertical,
  ToolText,
} from './styles';
import { TimelineGroup, TimelineGroupItems } from './timelineGroup';
import { getCompanyIcon, getStackIconByValue, getYearsArray } from './utils';
import { YearWrapper } from './yearWrapper';

const yearHeight = 100;
const years = getYearsArray('2010', '2024'); // TODO: get years range from content

const formatStackItems = (data: WP_REST_API_Post[]): TimelineGroupItems[] =>
  data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map(({ acf }: any, index: number) => {
      const { yearstart, yearend, stack } = acf;

      return {
        years: getYearsArray(yearstart, yearend),
        content: (
          <ToolGroupVertical key={index}>
            {stack.map((stackItem: { label: string; value: string }, stackItemIndex: number) => (
              <Tool key={stackItemIndex}>
                {getStackIconByValue(stackItem.value)}
                <ToolText>{stackItem.label}</ToolText>
              </Tool>
            ))}
          </ToolGroupVertical>
        ),
      };
    })
    .sort((a, b) => a.years[0] - b.years[0]);

const formatExperiencesItems = (data: WP_REST_API_Post[]): TimelineGroupItems[] =>
  data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((item: any) => {
      const { yearstart, yearend, companySlug } = item.acf;

      return {
        years: getYearsArray(yearstart, yearend),
        content: (
          <>
            {companySlug && <CompanyImage>{getCompanyIcon(companySlug)}</CompanyImage>}
            {parse(item.content.rendered)}
          </>
        ),
      };
    })
    .sort((a, b) => a.years[0] - b.years[0]);

const formatPortfolioItems = (data: WP_REST_API_Post[], onProjectClick: (id: number) => void): TimelineGroupItems[] =>
  data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((item: any) => {
      const { yearstart, yearend, groupposts } = item.acf;

      return {
        years: getYearsArray(yearstart, yearend),
        content: (
          <TimelineProjectsWrapper>
            {groupposts.map((post: WP_Post, index: number) => (
              <TimelineProjectWrapper key={index} onClick={() => onProjectClick(post.ID)}>
                {post.post_title}
              </TimelineProjectWrapper>
            ))}
          </TimelineProjectsWrapper>
        ),
      };
    })
    .sort((a, b) => a.years[0] - b.years[0]);

export const Timeline = () => {
  const timelineGroups = useMemo(
    () => [
      {
        title: 'Jobs',
        query: `${apiUrl}posts?categories=${config.categories.experiencesNew}&_fields=acf,content`,
        itemsFormatter: formatExperiencesItems,
      },
      {
        title: 'Stack',
        query: `${apiUrl}posts?categories=${config.categories.stackNew}&_fields=acf`,
        itemsFormatter: formatStackItems,
      },
      {
        title: 'Projects',
        query: `${apiUrl}posts?categories=26&_fields=acf`,
        itemsFormatter: (data: WP_REST_API_Post[]) => formatPortfolioItems(data, setSelectedProject),
      },
    ],
    [],
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [yearsArray, setYearsArray] = useState<number[]>(years);
  const [selectedProject, setSelectedProject] = useState<number | undefined>();
  const [selectedGroup, setSelectedGroup] = useState<string>(timelineGroups[0].title);

  const handleBackClick = () => {
    setSelectedProject(undefined);
  };

  const handleGroupClick = (groupName: string) => {
    setSelectedGroup(groupName);
  };

  return selectedProject ? (
    <TimelineProject id={selectedProject} onBackClick={handleBackClick} />
  ) : (
    <>
      <ResponsiveHeader
        groups={timelineGroups.map((group) => group.title)}
        selectedGroup={selectedGroup}
        onGroupClick={handleGroupClick}
      />
      <TimelineWrapper>
        <YearWrapper years={yearsArray} yearHeight={yearHeight} />
        <TimelineExperiencesWrapper>
          {timelineGroups.map(({ title, query, itemsFormatter }) => (
            <TimelineGroup
              key={title}
              title={title}
              isSelected={selectedGroup === title}
              yearHeight={yearHeight}
              query={query}
              itemsFormatter={itemsFormatter}
            />
          ))}
        </TimelineExperiencesWrapper>
      </TimelineWrapper>
    </>
  );
};
