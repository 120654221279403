import { ReactComponent as Email } from '../../images/links/email.svg';
import { ReactComponent as Github } from '../../images/links/github.svg';
import { ReactComponent as Gitlab } from '../../images/links/gitlab.svg';
import { ReactComponent as LinkedIn } from '../../images/links/linkedin.svg';
import { ReactComponent as Soundcloud } from '../../images/links/soundcloud.svg';
import { Contact, ContactLink, Contacts } from './styles';

export const Links = () => {
  return (
    <Contacts>
      <Contact>
        <ContactLink href="mailto:keturakis.augustinas@gmail.com">
          <Email />
          Email
        </ContactLink>
      </Contact>
      <Contact>
        <ContactLink href="https://www.linkedin.com/in/augustinas-keturakis/" target="_blank" rel="noopener noreferrer">
          <LinkedIn />
          LinkedIn
        </ContactLink>
      </Contact>
      <Contact>
        <ContactLink href="https://soundcloud.com/keturakis" target="_blank" rel="noopener noreferrer">
          <Soundcloud />
          SoundCloud
        </ContactLink>
      </Contact>
      <Contact>
        <ContactLink href="https://github.com/AugustinasK" target="_blank" rel="noopener noreferrer">
          <Github />
          GitHub
        </ContactLink>
      </Contact>
      <Contact>
        <ContactLink href="https://gitlab.com/keturakis.augustinas" target="_blank" rel="noopener noreferrer">
          <Gitlab />
          GitLab
        </ContactLink>
      </Contact>
    </Contacts>
  );
};
