// import { ReactComponent as Unity } from '../../images/tools/unity.svg';
// import { ReactComponent as Ableton } from '../../images/tools/ableton.svg';
// import { ReactComponent as Blender } from '../../images/tools/blender.svg';
import { ReactComponent as Kemdu } from '../../images/companies/kemdu.svg';
import { ReactComponent as Solutionlab } from '../../images/companies/solutionlab.svg';
import { ReactComponent as Syntropy } from '../../images/companies/syntropy.svg';
import { ReactComponent as Css3 } from '../../images/tools/css3.svg';
import { ReactComponent as Figma } from '../../images/tools/figma.svg';
import { ReactComponent as Git } from '../../images/tools/git.svg';
import { ReactComponent as Html5 } from '../../images/tools/html5.svg';
import { ReactComponent as Javascript } from '../../images/tools/javascript.svg';
import { ReactComponent as Jira } from '../../images/tools/jira.svg';
import { ReactComponent as Jquery } from '../../images/tools/jquery.svg';
import { ReactComponent as Nextjs } from '../../images/tools/nextjs.svg';
import { ReactComponent as Photoshop } from '../../images/tools/photoshop.svg';
import { ReactComponent as React } from '../../images/tools/react.svg';
import { ReactComponent as Typescript } from '../../images/tools/typescript.svg';
import { ReactComponent as Wordpress } from '../../images/tools/wordpress.svg';
import { ReactComponent as Xd } from '../../images/tools/xd.svg';

export const getYearsArray = (yearStart: string, yearEnd: string): number[] => {
  const startYear = parseInt(yearStart, 10);
  const endYear = parseInt(yearEnd, 10);

  if (isNaN(startYear) || isNaN(endYear)) {
    throw new Error('Invalid year input');
  }

  const yearsArray: number[] = [];

  for (let year = startYear; year <= endYear; year++) {
    yearsArray.push(year);
  }

  return yearsArray;
};

export const getStackIconByValue = (value: string) => {
  switch (value) {
    case 'css3':
      return <Css3 />;
    case 'html5':
      return <Html5 />;
    case 'figma':
      return <Figma />;
    case 'git':
      return <Git />;
    case 'javascript':
      return <Javascript />;
    case 'typescript':
      return <Typescript />;
    case 'jira':
      return <Jira />;
    case 'jquery':
      return <Jquery />;
    case 'next':
      return <Nextjs />;
    case 'photoshop':
      return <Photoshop />;
    case 'react':
      return <React />;
    case 'xd':
      return <Xd />;
    case 'wordpress':
      return <Wordpress />;
    default:
      return null;
  }
};

export const getCompanyIcon = (value: string) => {
  switch (value) {
    case 'solutionlab':
      return <Solutionlab />;
    case 'kemdu':
      return <Kemdu />;
    case 'syntropy':
      return <Syntropy />;
    default:
      return null;
  }
};
