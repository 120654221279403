import axios from 'axios';
import { ReactNode } from 'react';
import { useQuery } from 'react-query';
import { WP_REST_API_Post } from 'wp-types';

// import { ReactComponent as Jobs } from '../../images/jobs-icon.svg';
// import { ReactComponent as Projects } from '../../images/projects-icon.svg';
// import { ReactComponent as Stack } from '../../images/stack-icon.svg';
import { ContentLoader } from '../content-loader/content-loader';
import { TimelineExperience, TimelineExperienceWrapper, TimelineHeader } from './styles';

export interface TimelineGroupItems {
  years: number[];
  content: ReactNode;
}

interface TimelineGroup {
  title: string;
  items: TimelineGroupItems[];
}

interface TimelineGroupProps {
  title: string;
  yearHeight: number;
  isSelected: boolean;
  query: string;
  itemsFormatter: (items: WP_REST_API_Post[]) => TimelineGroupItems[];
}

export const TimelineGroup = ({ title, yearHeight, isSelected, query, itemsFormatter }: TimelineGroupProps) => {
  const { isLoading, error, data } = useQuery<TimelineGroup, Error>(title, () =>
    axios.get(query).then((response) => {
      return {
        title,
        items: itemsFormatter(response.data),
      };
    }),
  );

  return (
    <TimelineExperienceWrapper $visible={isSelected}>
      <TimelineHeader>{title}</TimelineHeader>
      <ContentLoader
        isLoading={isLoading}
        error={error?.message}
        hasContent={!!data}
        errorPrefix={`There was an error loading ${title} content:`}
      >
        {data &&
          data.items.map((item, index) => (
            <TimelineExperience key={index} style={{ height: `${item.years.length * yearHeight - 1}px` }}>
              {item.content}
            </TimelineExperience>
          ))}
      </ContentLoader>
    </TimelineExperienceWrapper>
  );
};
