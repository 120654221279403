import { ReactNode } from 'react';

import { ContentLoaderWrapper, Dot, Loader } from './styles';

interface ContentLoaderProps {
  children: ReactNode;
  isLoading: boolean;
  errorPrefix?: string;
  error?: string | null;
  height?: number;
  hasContent?: boolean;
  noContentText?: string;
}

export const ContentLoader = ({
  children,
  isLoading,
  errorPrefix = 'There was an error loading content:',
  error,
  height = 100,
  hasContent,
  noContentText = 'Nothing to display',
}: ContentLoaderProps) => {
  if (!isLoading && !error && hasContent) {
    return <>{children}</>;
  } else if (!isLoading && !error && !hasContent) {
    return <ContentLoaderWrapper $height={height}>{noContentText}</ContentLoaderWrapper>;
  } else if (isLoading) {
    return (
      <ContentLoaderWrapper $height={height}>
        <Loader>
          <Dot />
          <Dot $delay={160} />
          <Dot $delay={320} />
        </Loader>
      </ContentLoaderWrapper>
    );
  } else if (error) {
    return (
      <ContentLoaderWrapper $height={height}>
        <p>
          {errorPrefix} {error}
        </p>
      </ContentLoaderWrapper>
    );
  } else {
    return null;
  }
};
