import styled, { css } from 'styled-components';

import { responsive } from '../../../styles/theme';

export const MobileHeader = styled.div`
  display: none;
  border-bottom: 1px solid #ccc;
  margin-left: 8.25rem;
  background: #eee;
  flex-direction: column;

  ${responsive.md} {
    display: flex;
    margin-left: 6.25rem;
  }
`;

export const MobileHeaderH4 = styled.h4<{ $selected?: boolean }>`
  padding: 0;
  border-bottom: 2px solid #333;
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 2rem;
  text-transform: uppercase;
  flex-grow: 1;
  cursor: pointer;

  ${(props) =>
    props.$selected
      ? css`
          background: #edff91;
        `
      : css`
          &:hover {
            background: #ddd;
          }
        `}
`;
