import styled from 'styled-components';

import { responsive } from '../../../styles/theme';

export const IframeMedia = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PortfolioPostContent = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  overflow: hidden;

  ${responsive.md} {
    flex-direction: column;
  }
`;

export const PortfolioPostWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${responsive.sm} {
    width: 100%;
    flex-direction: column;
  }

  iframe {
    height: 300px;
  }
`;

export const GalleryGridColumn = styled.div`
  display: flex;
  gap: 1rem;
  flex-grow: 1;
  overflow-y: auto;

  ${responsive.md} {
    justify-content: center;
    flex-wrap: wrap;
  }
`;

export const GalleryGridItem = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  img,
  canvas {
    /* width: 100% !important; // override inline three.js styles */
    /* height: auto !important; // override inline three.js styles */
    max-width: 100%;
    height: auto;
    display: block;
  }
`;

export const GalleryGridContent = styled.div`
  overflow-y: auto;
  padding: 2rem 0;
  flex-grow: 1;
  z-index: 1;
`;

export const ImageOverlay = styled.div`
  position: relative;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.15s ease-in-out;
  opacity: 0;
  background: rgba(255, 255, 255, 0.1);
`;

export const ImageWrapper = styled.div`
  position: relative;
  cursor: pointer;

  /* &:hover {
    ${ImageOverlay} {
      opacity: 1;
    }
  } */
`;

export const ImagePreviewModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const PostDescription = styled.div<{ $responsive: boolean }>`
  max-width: 400px;
  flex: 1 1 100%;
  padding: 2rem 3rem;
  overflow-y: auto;
  text-align: right;
  line-height: 140%;
  z-index: 1;
  display: ${(props) => (props.$responsive ? 'none' : 'block')};

  ${responsive.md} {
    max-width: initial;
    flex: 0 0 auto;
    align-self: center;
    width: calc(100% - 4rem);
    display: ${(props) => (props.$responsive ? 'block' : 'none')};
    padding: 0 2rem 2rem 2rem;
  }
`;

export const MetaTitle = styled.h3`
  margin: 10px 0 10px 0;
`;

export const ProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
`;

export const ProjectViewBackButton = styled.h4`
  width: 100%;
  border-bottom: 2px solid #333;
  z-index: 1;
  font-size: 2rem;
  text-transform: uppercase;
  margin: 0 0 0 6.25rem;
  cursor: pointer;

  &:hover {
    background: #edff91;
  }

  ${responsive.sm} {
    margin-left: 2rem;
  }
`;

export const Bubble4 = styled.div`
  width: 60vw;
  height: 60vw;
  /* background: #edff91; */
  background: #fff;
  border-radius: 50%;
  flex-shrink: 0;
  position: fixed;
  bottom: -10vw;
  right: -10vw;
`;
