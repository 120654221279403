import styled from 'styled-components';

export const MainBlock = styled.div`
  background: #eeeeee;
  display: flex;
  letter-spacing: 0.4px;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
`;
