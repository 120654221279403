import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from 'styled-components';

import { Main } from './components/main/main';
import { GlobalStyle } from './styles/global';
import { theme } from './styles/theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  useEffect(() => {
    // initialize Google Analytics
    ReactGA.initialize('UA-56057530-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <Main />
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
