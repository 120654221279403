import parse from 'html-react-parser';

import { MetaTitle, PostDescription } from './styles';

interface PostMetaProps {
  title: string;
  content: string;
  responsive?: boolean;
}

export const ProjectMeta = ({ title, content, responsive = false }: PostMetaProps) => {
  return (
    <PostDescription $responsive={responsive}>
      <MetaTitle>{title}</MetaTitle>
      {parse(content)}
    </PostDescription>
  );
};
