import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  #root, body {
    display: flex;
    flex-direction: column;
  }
  body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
  }

  /* Content selection */
  *::selection {
      background-color: ${(props) => props.theme.colors.selection.background};
      color: ${(props) => props.theme.colors.selection.color};
  }

  /* Scrollbars */
  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #ddd;
    box-shadow: none;
    border-radius: 0;
  }

  *::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 0;
  }

  /* Headings */
  h1, h2, h3, h4, h5, h6 {
    width: 100%;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 400;
    line-height: 1;
  }
  h1 {
    font-size: 54px;
    margin: 2rem 0 1.25rem 0;
  }
  h2 {
    font-size: 48px;
    margin: 2rem 0 1.25rem 0;
  }
  h3 {
    font-size: 40px;
    margin: 2rem 0 1.25rem 0;
  }
  h4 {
    font-size: 24px;
    margin: 2rem 0 1.25rem 0
  }
  h5 {
    font-size: 18px;
    margin: 0.75rem 0 0.75rem 0;
  }
`;
